/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useHistory } from 'react-router-dom';
import Buttons from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { AiOutlineAim } from 'react-icons/ai';
import { MdCreditCard, MdLockOutline } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import GooglePlacesSearch from '../../components/RoutesAndLines/GooglePlacesSearch';

import { useAuth } from '../../hooks/auth';
import { LatLng } from '../../hooks/map';
import framepolitica from '../../assets/Shared/framepolitica.svg';
import {
  TitleSection,
  Main,
  ContainerCartao,
  CircleContainer,
  CircleWrapper,
  Circle,
  TransfacilCard,
  CardBenefit,
  Flex,
  FlexColumn,
  DialogContent,
  Instrucoes,
} from './styles';
import { Container, Content, Blur } from '../../styles/components';

import Header from '../../components/Home/Header';
import NavBar from '../../components/Home/NavBar';
import CartaoCliente from '../../assets/CartoesRecarga/CartaoCliente.svg';
import CartaoIdent from '../../assets/CartoesRecarga/cartão_identificado_frente.png';
import CartaoBeneficio from '../../assets/CartoesRecarga/CartaoBeneficio.svg';
import CartaoValeTransporte from '../../assets/CartoesRecarga/CartaoValeTransporte.svg';
import CardValeSocial from '../../assets/CartoesRecarga/card-vale-social.png';
import Oval from '../../assets/CartoesRecarga/Oval.svg';
import Entrar from '../../assets/CartoesRecarga/Vector.svg';
import Cartao from '../../assets/CartoesRecarga/Group 16.svg';
import Whatsapp from '../../assets/Shared/framewhatsapp.svg';
import Dolar from '../../assets/CartoesRecarga/$.svg';
import { ReactComponent as DollarIcon } from '../../assets/CartoesRecarga/dollar.svg';
import Mail from '../../assets/CartoesRecarga/mail 1.svg';
import Shop from '../../assets/Shared/shop2.svg';
import Bus from '../../assets/Shared/FrameBus.svg';
import Bilhete from '../../assets/CartoesRecarga/bilhete.svg';
import Button from '../../components/Button';
import Cardmaster from '../../assets/CartoesRecarga/cardmaster.png';
import Cardbeneficio from '../../assets/CartoesRecarga/cardbeneficio.png';
import Cardestudantil from '../../assets/CartoesRecarga/cardestudantil.png';
import Phone from '../../assets/CartoesRecarga/Phone+.svg';
import CardPurple from '../../assets/CartoesRecarga/CardPurple.svg';
import { useToast } from '../../hooks/toast';
import Rota from '../../assets/Shared/rota.svg';
import Footer from '../../components/Home/Footer';

import FaqSection from '../../components/FaqSection';
import Input from '../../components/Input';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

const CartoesRecarga: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });

  const { addToast } = useToast();
  const { user } = useAuth();
  const { push } = useHistory();
  const theme = useTheme();
  const [googleAddress, setGoogleAddress] = useState<string>('');
  const [latLng, setLatLng] = useState<LatLng>({} as LatLng);
  const [activeCard, setActiveCard] = useState<string>('cartao1');
  const [activeCardClient, setActiveCardClient] = useState<string>(
    'identificado',
  );
  const formRef = useRef<FormHandles>(null);

  const [opencard1, setOpencard1] = useState(false);
  const [opencard2, setOpencard2] = useState(false);
  const [opencard3, setOpencard3] = useState(false);

  const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRedirect = useCallback(
    path => {
      push(path);
    },
    [push],
  );

  const [maxWidthContainer1] = useState<DialogProps['maxWidth']>('sm');

  const changeActiveCard = useCallback(card => {
    setActiveCard(card);
  }, []);

  const changeActiveCardClient = useCallback(cartao => {
    setActiveCardClient(cartao);
  }, []);

  const handleClickOpenCard1 = () => {
    setOpencard1(true);
  };

  const handleCloseCard1 = () => {
    setOpencard1(false);
  };

  const handleClickOpenCard2 = () => {
    setOpencard2(true);
  };

  const handleCloseCard2 = () => {
    setOpencard2(false);
  };

  const handleClickOpenCard3 = () => {
    setOpencard3(true);
  };

  const handleCloseCard3 = () => {
    setOpencard3(false);
  };

  function openmaster() {
    const modal = document.getElementById('master');
    const disp = modal.style.display;
    modal.style.display = disp === 'none' ? 'block' : 'block';
    const modal1 = document.getElementById('button-ver-mais-master');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'block' ? 'none' : 'none';
  }

  function closemaster() {
    const modal = document.getElementById('master');
    const disp = modal.style.display;
    modal.style.display = disp === 'block' ? 'none' : 'none';
    const modal1 = document.getElementById('button-ver-mais-master');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'none' ? 'block' : 'block';
  }

  function openbeneficio() {
    const modal = document.getElementById('beneficio');
    const disp = modal.style.display;
    modal.style.display = disp === 'none' ? 'block' : 'block';
    const modal1 = document.getElementById('button-ver-mais-beneficio');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'block' ? 'none' : 'none';
  }

  function closebeneficio() {
    const modal = document.getElementById('beneficio');
    const disp = modal.style.display;
    modal.style.display = disp === 'block' ? 'none' : 'none';
    const modal1 = document.getElementById('button-ver-mais-beneficio');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'none' ? 'block' : 'block';
  }

  function openestudantil() {
    const modal = document.getElementById('estudantil');
    const disp = modal.style.display;
    modal.style.display = disp === 'none' ? 'block' : 'block';
    const modal1 = document.getElementById('button-ver-mais-estudantil');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'block' ? 'none' : 'none';
  }

  function closeestudantil() {
    const modal = document.getElementById('estudantil');
    const disp = modal.style.display;
    modal.style.display = disp === 'block' ? 'none' : 'none';
    const modal1 = document.getElementById('button-ver-mais-estudantil');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'none' ? 'block' : 'block';
  }

  function openvalesocial() {
    const modal = document.getElementById('valesocial');
    const disp = modal.style.display;
    modal.style.display = disp === 'none' ? 'block' : 'block';
    const modal1 = document.getElementById('button-ver-mais-valesocial');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'block' ? 'none' : 'none';
  }

  function closevalesocial() {
    const modal = document.getElementById('valesocial');
    const disp = modal.style.display;
    modal.style.display = disp === 'block' ? 'none' : 'none';
    const modal1 = document.getElementById('button-ver-mais-valesocial');
    const disp1 = modal.style.display;
    modal1.style.display = disp1 === 'none' ? 'block' : 'block';
  }

  const handleScroolTo = useCallback(
    (activeItem: string) => {
      changeActiveCard(activeItem);

      const element = document.getElementById('content-passos');
      element.scrollIntoView();
      element.scrollIntoView(false);
      element.scrollIntoView({ block: 'end' });
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    },
    [changeActiveCard],
  );

  const handleMyLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const encodedQueryParams = encodeURI(
          `?lat=${position.coords.latitude}&lng=${position.coords.longitude}`,
        );
        window.location.href = `/pontos-de-recarga/${encodedQueryParams}`;
      },
      () => null,
    );
  }, []);

  const handleRedirectMap = useCallback(() => {
    if (googleAddress) {
      const encodedQueryParams = encodeURI(
        `?lat=${latLng.lat}&lng=${latLng.lng}&address=${googleAddress}`,
      );
      window.location.href = `/pontos-de-recarga/${encodedQueryParams}`;
    } else {
      addToast({
        type: 'info',
        title: 'Selecione primeiro o Endereço',
        description: 'Não foi possível redirecionar para o mapa',
      });
    }
  }, [googleAddress, latLng, addToast]);

  const setMapCoordinates = useCallback(async address => {
    const geoCode = await getGeocode({ address });
    const { lat, lng } = await getLatLng(geoCode[0]);
    setLatLng({ lat, lng });
    setGoogleAddress(address);
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(document.location.search);
    const active = query.get('active');
    if (active === `'recarga'`) handleScroolTo('cartao1');
    if (active === `'bloquear'`) handleScroolTo('cartao3');
  }, [handleScroolTo]);

  return (
    <>
      <Container>
        <Content>
          <Header />
        </Content>
      </Container>
      <Container backgroundImage className="companies-container">
        <Blur>
          <Content className="content">
            <NavBar />
            <TitleSection>
              <h1 className="h1">Cartões BHBUS</h1>
              <span className="subtitle">
                Cartão BHBUS é o cartão de bilhetagem eletrônica utilizado no
                transporte coletivo urbano (ônibus e metrô) de Belo Horizonte.
              </span>
            </TitleSection>
          </Content>
        </Blur>
      </Container>
      <Container>
        <Content>
          <Main>
            <h1 className="title" id="nossoscartoes">
              Nossos cartões
            </h1>
            <span className="subtitle1">
              Confira os diferenciais de cada produto:
            </span>
            <Flex className="cartoes-bhbus">
              <FlexColumn className="cartao-bhbus">
                <Buttons
                  variant="outlined"
                  color="default"
                  onClick={handleClickOpenCard1}
                  className="cards"
                >
                  <img
                    src={CartaoValeTransporte}
                    alt="Cartão vale transporte BHBus"
                  />
                </Buttons>
                <Dialog
                  maxWidth={maxWidthContainer1}
                  fullScreen={fullScreen}
                  open={opencard1}
                  onClose={handleCloseCard1}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className="dialog"
                >
                  <DialogActions className="caixa">
                    <Buttons onClick={handleCloseCard1} color="primary">
                      X
                    </Buttons>
                  </DialogActions>
                  <DialogContent id="alert-dialog-title">
                    <div className="container">
                      <img
                        src={CartaoValeTransporte}
                        alt="Cartão vale transporte BHBus"
                      />
                      <h3 className="title-benefit-vale">
                        CARTÃO BHBUS VALE TRANSPORTE
                      </h3>
                      <p className="vale">
                        Adquiridos pelos empregadores ou pessoas jurídicas de
                        direito público e fornecidos aos beneficiários
                        (empregados) do vale-transporte.
                      </p>
                      <p>
                        <a href="/para-empresas" target="blank">
                          <Button
                            color="orange"
                            padding="1"
                            rounded
                            name="recarregar"
                            type="button"
                            className="contratar"
                          >
                            CONTRATAR
                          </Button>
                        </a>
                      </p>
                    </div>
                  </DialogContent>
                </Dialog>
                <span className="span-cartao">
                  Adquiridos pelos empregadores ou pessoas jurídicas de direito
                  público e fornecidos aos beneficiários (empregados) do
                  vale-transporte.
                </span>
                <Buttons
                  variant="outlined"
                  color="default"
                  onClick={handleClickOpenCard1}
                  className="card-ver-mais"
                >
                  <span className="ver_mais">VER MAIS &gt;</span>
                </Buttons>
              </FlexColumn>

              <FlexColumn className="cartao-bhbus">
                <Buttons
                  variant="outlined"
                  color="default"
                  onClick={handleClickOpenCard2}
                  className="cards"
                >
                  <img src={CartaoCliente} alt="Cartão cliente BHBus" />
                </Buttons>
                <Dialog
                  maxWidth={maxWidth}
                  fullScreen={fullScreen}
                  open={opencard2}
                  onClose={handleCloseCard2}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogActions className="caixa">
                    <Buttons onClick={handleCloseCard2} color="primary">
                      X
                    </Buttons>
                  </DialogActions>
                  <DialogContent>
                    <div className="container1">
                      {activeCardClient === 'identificado' && (
                        <>
                          <img
                            src={CartaoIdent}
                            alt="Cartão Identicado BHBus"
                            className="img-identificado"
                          />
                          <p>
                            O cartão BHBUS identificado pode ser adquirido por
                            qualquer cliente que utilize o sistema de
                            transporte. Nessa modalidade, em caso de perda ou
                            roubo, o cartão pode ser bloqueado e os créditos
                            remanescentes poderão ser transferidos para uma
                            segunda via.
                          </p>
                        </>
                      )}
                      {activeCardClient === 'portador' && (
                        <>
                          <img
                            src={CartaoCliente}
                            alt="Cartão cliente BHBus"
                            className="img-cliente"
                          />
                          <p>
                            O cartão BHBUS ao portador pode ser adquirido por
                            qualquer cliente que utilize o sistema de
                            transporte. Nessa modalidade, em caso de perda ou
                            roubo, o cartão não pode ser bloqueado e não permite
                            o ressarcimento dos créditos.
                          </p>
                        </>
                      )}

                      <p>Clique abaixo e conheça cada modalidade:</p>
                      <CardBenefit
                        active={!!(activeCardClient === 'identificado')}
                        onClick={() => changeActiveCardClient('identificado')}
                      >
                        <div className="modalidades">
                          <img
                            src={framepolitica}
                            alt="politica"
                            className="frame"
                          />
                          <h3>MODALIDADE IDENTIFICADO</h3>
                        </div>
                      </CardBenefit>
                      <CardBenefit
                        active={!!(activeCardClient === 'portador')}
                        onClick={() => changeActiveCardClient('portador')}
                      >
                        <div className="modalidades">
                          <img
                            src={framepolitica}
                            alt="politica"
                            className="frame"
                          />
                          <h3>MODALIDADE AO PORTADOR</h3>
                        </div>
                      </CardBenefit>
                    </div>
                    {activeCardClient === 'identificado' && (
                      <>
                        <div className="container2">
                          <h3 className="title-cliente">
                            CARTÃO BHBUS CLIENTE IDENTIFICADO
                          </h3>

                          <h3 className="title-benefit1">COMO ADQUIRIR?</h3>
                          <p>
                            O cartão BHBUS Cliente Identificado pode ser
                            adquirido gratuitamente no Posto Floresta, situado
                            na rua Aquiles Lobo, 504, Floresta.
                          </p>
                          <p>
                            Nas estações: Verificar disponibilidade pelo
                            número(31)3248-7300.
                          </p>
                          <h3 className="title-benefit1">COMO RECARREGAR?</h3>
                          <p>
                            A carga e recarga podem ser feitas em qualquer um
                            dos postos de venda do Transfácil, estações BHBUS e
                            estações do metrô. É possível recarregar também no
                            app BHBUS ou pelo nosso site&nbsp;
                            <a href="/login" target="blank">
                              <strong>clicando aqui</strong>
                            </a>
                            .
                          </p>
                          <h3 className="title-benefit1">
                            PERDI O CARTÃO, O QUE FAZER?
                          </h3>
                          <p>
                            O cliente deverá comunicar ao TRANSFÁCIL por meio do
                            whatsapp&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?phone=553132487300&amp;text=Olá%20Transfácil"
                              target="blank"
                            >
                              <strong>(31) 3248-7300</strong>
                            </a>
                            &nbsp;ou e-mail&nbsp;
                            <a href="mailto:sac@transfacil.com.br">
                              <strong>sac@transfacil.com.br</strong>
                            </a>
                            .
                          </p>
                          <p>
                            O bloqueio será realizado no prazo máximo de 72
                            horas após a notificação. Para retirar a segunda via
                            é necessário aguardar 3 dias úteis após o bloqueio e
                            comparecer a Loja Floresta (Rua Aquiles Lobo, 504 -
                            Floresta) apresentando documento original com foto e
                            realizar o pagamento no valor de R$ 15,00.
                          </p>
                        </div>
                      </>
                    )}
                    {activeCardClient === 'portador' && (
                      <>
                        <div className="container2">
                          <h3 className="title-cliente">
                            CARTÃO BHBUS CLIENTE AO PORTADOR
                          </h3>

                          <h3 className="title-benefit1">COMO ADQUIRIR?</h3>
                          <p>
                            O cartão BHBUS ao Portador pode ser adquirido em
                            todas as estações BHBUS, Lojas Transfácil ou
                            estações do Metrô.
                          </p>
                          <p>Custo unitário do cartão: R$2,00.</p>
                          <h3 className="title-benefit1">COMO RECARREGAR?</h3>
                          <p>
                            A carga e recarga podem ser feitas em qualquer um
                            dos postos de venda do Transfácil, estações BHBUS e
                            estações do metrô. É possível recarregar também no
                            app BHBUS ou pelo nosso site &nbsp;
                            <a href="/login" target="blank">
                              <strong>clicando aqui</strong>
                            </a>
                            .
                          </p>
                          <h3 className="title-benefit1">
                            PERDI O CARTÃO, E AGORA?
                          </h3>
                          <p>
                            O cartão BHBUS ao Portador não permite bloqueio,
                            consequentemente não possui a possibilidade de
                            retirada de 2º via.
                          </p>
                        </div>
                      </>
                    )}
                  </DialogContent>
                </Dialog>
                <span className="span-cartao">
                  Pode ser adquirido por qualquer usuário do Serviço de
                  Transporte. Na modalidade Identificado, em caso de perda ou
                  roubo, o cartão pode ser bloqueado e os créditos remanescentes
                  poderão ser transferidos para uma segunda via.
                </span>
                <Buttons
                  variant="outlined"
                  color="default"
                  onClick={handleClickOpenCard2}
                  className="card-ver-mais"
                >
                  <span className="ver_mais">VER MAIS &gt;</span>
                </Buttons>
              </FlexColumn>

              <FlexColumn className="cartao-bhbus">
                <Buttons
                  variant="outlined"
                  color="default"
                  onClick={handleClickOpenCard3}
                  className="cards"
                >
                  <img src={CartaoBeneficio} alt="Cartão beneficio BHBus" />
                </Buttons>
                <Dialog
                  maxWidth={maxWidth}
                  fullScreen={fullScreen}
                  open={opencard3}
                  onClose={handleCloseCard3}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogActions className="caixa">
                    <Buttons onClick={handleCloseCard3} color="primary">
                      X
                    </Buttons>
                  </DialogActions>
                  <DialogContent>
                    <div>
                      <div className="flex-container">
                        <div className="container-img">
                          <img
                            src={Cardmaster}
                            alt="Cartão beneficio BHBUS Master"
                          />
                        </div>
                        <div className="container2">
                          <h1 className="title-benefit">BHBUS Master</h1>
                          <p>
                            Destinado a clientes com idade{' '}
                            <strong>
                              igual ou superior a 65 (sessenta e cinco) anos
                            </strong>
                            , que desejam transpor gratuitamente as roletas dos
                            ônibus do sistema de Transporte Coletivo Municipal
                            em Belo Horizonte.
                          </p>
                          <h3 className="title-benefit1">COMO ADQUIRIR?</h3>
                          <p>
                            Basta dirigir-se ao Posto do Transfácil no BH
                            Resolve (Av. Santos Dumont, 363 - Centro), portando
                            documento de identificação com foto, CPF, xerox do
                            RG e comprovante de endereço atual (máximo 6 meses).
                          </p>
                          <button
                            type="button"
                            id="button-ver-mais-master"
                            className="ver-mais"
                            onClick={openmaster}
                          >
                            <p>VER MAIS</p>
                          </button>

                          <div id="master" className="cards-benefit">
                            <p>
                              Os atendimentos ocorrem de segunda a sexta-feira,
                              das 8h às 17h, por ordem de chegada, não sendo
                              necessário agendamento prévio. O cartão é entregue
                              na hora.
                            </p>
                            <p>
                              Para obter o Cartão BHBUS Master, não é necessário
                              residir em Belo Horizonte, mas sua utilização deve
                              ser exclusivamente na cidade.
                            </p>
                            <h3 className="title-benefit1">
                              PERDI O CARTÃO, O QUE FAZER?
                            </h3>
                            <p>
                              O cliente deverá comunicar ao TRANSFÁCIL através
                              do whatsapp&nbsp;
                              <a
                                href="https://api.whatsapp.com/send?phone=553132487300&amp;text=Olá%20Transfácil"
                                target="blank"
                              >
                                <strong>(31) 3248-7300</strong>
                              </a>
                              &nbsp;ou E-mail&nbsp;
                              <a href="mailto:sac@transfacil.com.br">
                                <strong>sac@transfacil.com.br</strong>
                              </a>
                              .
                            </p>
                            <p>
                              Para obter a segunda via, é necessário comparecer
                              ao Posto do Transfácil no BH Resolve (Av. Santos
                              Dumont, 363 - Centro) apresentando documento
                              original com foto e boletim de ocorrência.
                            </p>
                            <p>
                              Os atendimentos ocorrem de segunda a sexta-feira,
                              das 8h às 17h, por ordem de chegada, sem
                              necessidade de agendamento prévio. A partir da
                              terceira via em diante, será cobrado o valor
                              correspondente a 7 vezes a tarifa predominante.
                            </p>
                            <h3 className="title-benefit1">COMO RENOVAR?</h3>
                            <p>
                              A revalidação do cartão deve ser feita durante o
                              mês de aniversário, após a mensagem "Revalidar
                              cartão" aparecer no validador localizado nas
                              roletas.
                            </p>
                            <p>
                              Para realizar a revalidação, o titular do
                              benefício deve comparecer ao Posto do Transfácil,
                              no BH Resolve, localizado na Av. Santos Dumont,
                              363 - Centro, portando: Cartão BHBUS Master,
                              Documento de Identidade, CPF e comprovante de
                              endereço atual (máximo 6 meses).
                            </p>
                            <p>
                              Horário de Funcionamento: segunda à sexta, de 08h
                              às 17h. O atendimento será realizado por ordem de
                              chegada.
                            </p>
                            <p>
                              Caso não possua o cartão, é necessário registrar
                              um Boletim de Ocorrência de perda/roubo e pagar o
                              valor de 7 vezes a tarifa vigente.
                            </p>
                            <button
                              type="button"
                              className="ver-mais"
                              onClick={closemaster}
                            >
                              <p>VER MENOS</p>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex-container">
                        <div className="container-img">
                          <img
                            src={Cardbeneficio}
                            alt="Cartão beneficio BHBUS Beneficio"
                          />
                        </div>
                        <div className="container2">
                          <h1 className="title-benefit-benefit">
                            BHBUS Benefício
                          </h1>
                          <p>
                            Destinado a pessoas com insuficiência renal crônica
                            em terapia renal substitutiva, pessoas com
                            deficiência física, auditiva, mental ou visual e
                            pessoas com transtorno do espectro autista, sendo
                            necessária a apresentação do cartão para que se
                            possa usufruir da gratuidade.
                          </p>
                          <h3 className="title-benefit1">COMO ADQUIRIR?</h3>
                          <p>
                            Basta acessar o site&nbsp;
                            <a
                              href="https://servicos.pbh.gov.br"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <strong>https://servicos.pbh.gov.br</strong>
                            </a>
                            &nbsp; no campo de busca, digite Benefício da
                            gratuidade. Escolha a opção Inclusão e veja o passo
                            a passo para fazer a solicitação.
                          </p>
                          <button
                            type="button"
                            id="button-ver-mais-beneficio"
                            className="ver-mais"
                            onClick={openbeneficio}
                          >
                            <p>VER MAIS</p>
                          </button>
                          <div id="beneficio" className="cards-benefit">
                            <h3 className="title-benefit1">
                              PERDI MEU CARTÃO, E AGORA?
                            </h3>
                            <p>
                              O cliente deverá comunicar ao TRANSFÁCIL por meio
                              do whatsapp&nbsp;
                              <a
                                href="https://api.whatsapp.com/send?phone=553132487300&amp;text=Olá%20Transfácil"
                                target="blank"
                              >
                                <strong>(31) 3248-7300</strong>
                              </a>
                              &nbsp;ou e-mail&nbsp;
                              <a href="mailto:sac@transfacil.com.br">
                                <strong>sac@transfacil.com.br</strong>
                              </a>
                              .
                            </p>
                            <p>
                              O bloqueio será realizado no prazo máximo de 72
                              horas após a notificação. Para retirar a segunda
                              via é necessário comparecer no BH Resolve (Av.
                              Santos Dumont, 363 - Centro) apresentando
                              documento original com foto.
                            </p>
                            <p>
                              Os atendimentos serão de segunda à sexta-feira, de
                              8h às 12h e de 13h às 17h, por ordem de chegada,
                              não sendo possível o agendamento prévio. A partir
                              da segunda via em diante será cobrado valor
                              correspondente a 7 vezes a tarifa predominante.
                            </p>
                            <h3 className="title-benefit1">COMO RENOVAR?</h3>
                            <p>
                              A revalidação do cartão deve ser feita ao longo do
                              mês de aniversário, após aparecer a mensagem
                              "Revalidar cartão" no validador localizado nas
                              roletas.
                            </p>
                            <p>
                              Para realizar a revalidação, o titular do
                              benefício deve comparecer ao Posto do Transfácil,
                              no BH RESOLVE - Endereço: Av. Santos Dumont, 363 -
                              Centro, portando: Cartão BHBUS Benefício Inclusão,
                              Documento de Identidade, CPF e comprovante de
                              endereço atual (máximo 6 meses).
                            </p>
                            <p>
                              Horário de Funcionamento: segunda à sexta, de 08h
                              às 17h. O atendimento será feito por ordem de
                              chegada.
                            </p>
                            <p>
                              Caso não possua o cartão, é necessário realizar um
                              Boletim de Ocorrência de perda/roubo e pagar o
                              valor de 7 vezes a tarifa vigente.
                            </p>
                            <button
                              type="button"
                              className="ver-mais"
                              onClick={closebeneficio}
                            >
                              <p>VER MENOS</p>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex-container">
                        <div className="container-img">
                          <img
                            src={Cardestudantil}
                            alt="Cartão beneficio Passe Estudantil"
                          />
                        </div>
                        <div className="container2">
                          <h1 className="title-benefit-estudantil">
                            Passe Estudantil
                          </h1>
                          <p>
                            O Passe Estudantil é um benefício oferecido pela PBH
                            e destinado aos alunos do Ensino Médio e da Educação
                            de Jovens e Adultos (EJA) inscritos e regulares em
                            instituições de ensino localizadas em Belo Horizonte
                            e que habitem a, no mínimo, 1km da escola que
                            frequentam.
                          </p>
                          <p>
                            O auxílio equivale a 100% do valor das tarifas pagas
                            no percurso ida e volta entre a residência e a
                            escola do estudante beneficiário, sendo válido para
                            o serviço de transporte coletivo de passageiros do
                            Município de Belo Horizonte.
                          </p>
                          <h3 className="title-benefit1">COMO ADQUIRIR?</h3>
                          <p>
                            O início das inscrições é divulgado nos canais
                            oficiais da Prefeitura de Belo Horizonte. Para mais
                            informações, acesse: &nbsp;
                            <a
                              href="https://prefeitura.pbh.gov.br/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <strong>https://prefeitura.pbh.gov.br</strong>
                            </a>
                          </p>
                          <button
                            type="button"
                            id="button-ver-mais-estudantil"
                            className="ver-mais"
                            onClick={openestudantil}
                          >
                            <p>VER MAIS</p>
                          </button>
                          <div id="estudantil" className="cards-benefit">
                            <h3 className="title-benefit1">
                              PERDI O CARTÃO, E AGORA?
                            </h3>
                            <p>
                              O estudante deverá comunicar ao Transfácil, por
                              meio do WhatsApp:
                              <a
                                href="https://api.whatsapp.com/send?phone=553132487300&amp;text=Olá%20Transfácil"
                                target="blank"
                              >
                                <strong>(31) 3248-7300</strong>
                              </a>
                              &nbsp;ou e-mail&nbsp;
                              <a href="mailto:sac@transfacil.com.br">
                                <strong>sac@transfacil.com.br</strong>
                              </a>
                              .
                            </p>
                            <p>
                              Para retirada da segunda via é necessário aguardar
                              3 (três) dias úteis após a notificação do bloqueio
                              e comparecer no BH Resolve, na Av. Santos Dumont,
                              363 - Centro. É necessário apresentar documento
                              original com foto e será cobrado o valor
                              correspondente a 7 tarifas predominantes.
                            </p>
                            <p>
                              Os atendimentos serão de segunda à sexta-feira, de
                              8h às 12h e de 13h às 17h, por ordem de chegada,
                              não sendo possível o agendamento prévio.
                            </p>
                            <button
                              type="button"
                              className="ver-mais"
                              onClick={closeestudantil}
                            >
                              <p>VER MENOS</p>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex-container">
                        <div className="container-img">
                          <img
                            src={CardValeSocial}
                            alt="Cartão beneficio Vale Social"
                          />
                        </div>
                        <div className="container2">
                          <h1 className="title-benefit-estudantil">
                            Vale-Transporte Social
                          </h1>
                          <p>
                            O Vale-Transporte Social engloba duas categorias de
                            gratuidades para o transporte coletivo por ônibus,
                            são eles: o Auxílio Transporte Mulher e o
                            Vale-Transporte Saúde.
                          </p>
                          <h3 className="title-benefit1">
                            Auxílio Transporte Mulher
                          </h3>
                          <p>
                            O Auxílio Transporte Mulher é um benefício destinado
                            a mulheres em situação de violência doméstica e
                            familiar, que residem em Belo Horizonte,
                            apresentando vulnerabilidade econômica ou social e
                            sendo assistidas pelo Poder Público. Essa iniciativa
                            foi estabelecida pela Lei n° 11.538/23 e pelo
                            Decreto nº 18.412/2023.
                          </p>
                          <p>
                            Após o cumprimento de todas as exigências, o
                            benefício será concedido por um período de 2 (dois)
                            meses. Sua renovação poderá ocorrer mediante uma
                            identificação realizada pelos serviços municipais de
                            atendimento à mulher em situação de violência
                            doméstica e familiar, nos locais como o CEAM -
                            Centro Especializado de Atendimento à Mulher -
                            Benvinda e os Centros de Referência Especializado de
                            Assistência Social - CREAS, por meio de atendimento
                            presencial.
                          </p>
                          <p>
                            Mais informações: &nbsp;
                            <a
                              href="https://prefeitura.pbh.gov.br/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <strong>https://prefeitura.pbh.gov.br</strong>
                            </a>
                          </p>
                          <button
                            type="button"
                            id="button-ver-mais-valesocial"
                            className="ver-mais"
                            onClick={openvalesocial}
                          >
                            <p>VER MAIS</p>
                          </button>
                          <div id="valesocial" className="cards-benefit">
                            <h3 className="title-benefit1">
                              Vale-Transporte Saúde
                            </h3>
                            <p>
                              O Vale-Transporte Saúde é um benefício voltado aos
                              cidadãos que necessitam se locomover para
                              consultas e procedimentos médicos dentro do escopo
                              do Sistema Único de Saúde - SUS, com prioridade de
                              atendimento para pacientes com diagnóstico
                              oncológico. Este benefício foi estabelecido por
                              meio da legislação, especificamente pela Lei n°
                              11.458/23 e pelo Decreto n° 18.409/23.
                            </p>
                            <p>
                              Mais informações: &nbsp;
                              <a
                                href="https://prefeitura.pbh.gov.br/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <strong>https://prefeitura.pbh.gov.br</strong>
                              </a>
                            </p>
                            <button
                              type="button"
                              className="ver-mais"
                              onClick={closevalesocial}
                            >
                              <p>VER MENOS</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                <span className="span-cartao">
                  Pode ser adquirido pelos beneficiários da gratuidade dos
                  serviços de transporte coletivo. O meio passe estudantil é um
                  benefício oferecido correspondednte a um desconto de 50% do
                  valor da tarifa, no percurso entre a residência e a escola do
                  estudante.
                </span>
                <Buttons
                  variant="outlined"
                  color="default"
                  onClick={handleClickOpenCard3}
                  className="card-ver-mais"
                >
                  <span className="ver_mais">VER MAIS &gt;</span>
                </Buttons>
              </FlexColumn>
            </Flex>
            <ContainerCartao id="cardsection">
              <TransfacilCard
                active={!!(activeCard === 'cartao1')}
                onClick={() => handleScroolTo('cartao1')}
              >
                <DollarIcon />
                <h3>RECARREGAR</h3>
                <h3>MEU CARTÃO</h3>
              </TransfacilCard>
              <TransfacilCard
                active={!!(activeCard === 'cartao2')}
                onClick={() => handleScroolTo('cartao2')}
              >
                <MdCreditCard size={48} className="iconpurple" />
                <h3>COMPRAR</h3>
                <h3>UM CARTÃO</h3>
              </TransfacilCard>
              <TransfacilCard
                active={!!(activeCard === 'cartao3')}
                onClick={() => handleScroolTo('cartao3')}
              >
                <MdLockOutline size={48} className="iconpurple" />
                <h3>BLOQUEAR</h3>
                <h3>MEU CARTÃO</h3>
              </TransfacilCard>
            </ContainerCartao>
            <div id="content-passos" className="content-passos">
              {activeCard === 'cartao1' && (
                <>
                  <h1 className="h1">Recarga Online</h1>
                  <span>Confira o passo a passo:</span>
                  <CircleContainer>
                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Entrar} alt="" className="img" />
                      </Circle>
                      <span>Cadastre-se ou faça seu login</span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Cartao} alt="" className="img2" />
                      </Circle>
                      <span>Adicione o cartão que deseja recarregar</span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Dolar} alt="" className="img3" />
                      </Circle>
                      <span>
                        Escolha o valor da recarga e a forma de pagamento
                      </span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Mail} alt="" className="img4" />
                      </Circle>
                      <span>
                        Aguarde a confirmação por e-mail e tudo pronto,já pode
                        usar seu BHBUS!
                      </span>
                    </CircleWrapper>
                  </CircleContainer>

                  <Button
                    color="orange"
                    padding="1"
                    rounded
                    name="recarregar"
                    className="recarregar-cartao"
                    onClick={() => {
                      if (user) {
                        handleRedirect(window.open('/meus-cartoes'));
                      } else {
                        handleRedirect(window.open('/login'));
                      }
                    }}
                    type="button"
                  >
                    RECARREGAR MEU CARTÃO
                  </Button>

                  <h1 className="h1-1">Recarga nos postos de atendimento</h1>
                  <h2>Locais que você pode recarregar:</h2>
                  <CircleContainer>
                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Shop} alt="" className="img5" />
                      </Circle>
                      <span>Lojas Transfácil e estações BHBUS</span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Bilhete} alt="" className="img6" />
                      </Circle>
                      <span>Bilheterias do metrô</span>
                    </CircleWrapper>
                  </CircleContainer>
                </>
              )}
              {activeCard === 'cartao2' && (
                <>
                  <h1 className="h1">Comprar seu BHBUS</h1>
                  <CircleContainer>
                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={CardPurple} alt="" className="img9" />
                      </Circle>
                      <span>Escolha o melhor cartão para você</span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Shop} alt="" className="img5" />
                      </Circle>
                      <span>
                        Vá até um ponto de venda físico BHBUS e adquira o seu
                        cartão
                      </span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Phone} alt="" className="img7 phone" />
                      </Circle>
                      <span>
                        Faça a primeira recarga na hora e se cadastre no site
                        para recarga online
                      </span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Bus} alt="" className="img6" />
                      </Circle>
                      <span>
                        Depois é só usar seu BHBUS para se movimentar na cidade.
                      </span>
                    </CircleWrapper>
                  </CircleContainer>
                  <h2 className="text-dark-grey">
                    Locais que você pode adquirir um cartão BHBUS:
                  </h2>
                  <CircleContainer>
                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Shop} alt="" className="img5" />
                      </Circle>
                      <span>Lojas Transfácil e estações BHBUS</span>
                    </CircleWrapper>

                    <CircleWrapper>
                      <Circle>
                        <img src={Oval} alt="fundo branco" />
                        <img src={Bilhete} alt="" className="img6" />
                      </Circle>
                      <span>Bilheterias do metrô</span>
                    </CircleWrapper>
                  </CircleContainer>

                  <h2 className="text-dark-grey">
                    Confira as lojas ou os locais de recarga perto de você.
                  </h2>

                  <div className="flex">
                    {isLoaded && (
                      <div className="googleContainer">
                        <button
                          type="button"
                          className="locationContainer"
                          onClick={handleMyLocation}
                        >
                          <AiOutlineAim /> Usar minha localização
                        </button>
                        {/* <GooglePlacesSearch
                          onSelect={address => setMapCoordinates(address)}
                          placeholder="Endereço"
                          type="recharge"
                          button
                          buttonCallBack={handleRedirectMap}
                        /> */}

                        <Form ref={formRef} onSubmit={handleRedirectMap}>
                          <Input
                            name="endereçoOrigem"
                            onChange={e => setMapCoordinates(e.target.value)}
                            defaultValue={googleAddress}
                            placeholder="Endereço"
                            type="recharge"
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              style={{
                                marginBottom: '4px',
                                marginTop: '4px',
                                marginLeft: '-4px',
                              }}
                              type="submit"
                              color="purple"
                              padding="4"
                              rounded
                            >
                              Buscar
                            </Button>
                          </div>
                        </Form>
                      </div>
                    )}
                    <div>
                      <img
                        src={Rota}
                        alt="Mapa indicando o início e fim dde um trajeto."
                        className="img-gps"
                      />
                    </div>
                  </div>
                </>
              )}
              {activeCard === 'cartao3' && (
                <>
                  <Instrucoes>
                    <h1 className="h1">Perda ou roubo de cartão</h1>
                    <span className="text-purple">
                      Para cartões BHBUS identificados:
                    </span>
                    <CircleContainer>
                      {/* <CircleWrapper>
                        <div className="wrapper-circle">
                          <Circle>
                            <img src={Oval} alt="fundo branco" />
                            <img src={Entrar} alt="" className="img" />
                          </Circle>
                          <span>Cadastre-se ou faça seu login </span>
                        </div>
                      </CircleWrapper>

                      <CircleWrapper>
                        <div className="wrapper-circle">
                          <Circle>
                            <img src={Oval} alt="fundo branco" />
                            <img src={Lupa} alt="" className="img" />
                          </Circle>
                          <span>Selecione o cartão que deseja bloquear</span>
                        </div>
                      </CircleWrapper>

                      <CircleWrapper>
                        <div className="wrapper-circle">
                          <Circle>
                            <img src={Oval} alt="fundo branco" />
                            <img src={Phone2} alt="" className="img8" />
                          </Circle>
                          <span>
                            Pronto! Em até 72h você poderá retirar a 2ª via.
                          </span>
                        </div>
                      </CircleWrapper>

                      <CircleWrapper>
                        <div className="wrapper-circle">
                          <Circle>
                            <img src={Oval} alt="fundo branco" />
                            <img src={Shop} alt="" className="img5" />
                          </Circle>
                          <span>
                            Depois é só buscar na loja Transfácil mais próxima
                            de você.
                          </span>
                        </div>
                      </CircleWrapper> */}

                      <CircleWrapper>
                        <div className="wrapper-circle">
                          <a
                            href="http://api.whatsapp.com/send?phone=553132487300"
                            target="blank"
                            className="no-underline"
                          >
                            <Circle>
                              <img src={Oval} alt="fundo branco" />
                              <img src={Whatsapp} alt="" className="img10" />
                            </Circle>
                          </a>
                          <span>
                            <a
                              href="http://api.whatsapp.com/send?phone=553132487300"
                              target="blank"
                              className="no-underline"
                            >
                              Para bloquear o seu cartão identificado entre em
                              contato com o Whatsapp, clicando&nbsp;
                            </a>
                            <a
                              href="http://api.whatsapp.com/send?phone=553132487300"
                              target="blank"
                            >
                              aqui.
                            </a>
                          </span>
                        </div>
                      </CircleWrapper>
                    </CircleContainer>
                    {/* <Button
                    color="orange"
                    padding="1"
                    rounded
                    name="recarregar"
                    onClick={() => {
                      if (user) {
                        handleRedirect('/meus-cartoes');
                      } else {
                        handleRedirect('/login');
                      }
                    }}
                    type="button"
                  >
                    BLOQUEAR MEU CARTÃO
                  </Button> */}
                  </Instrucoes>
                </>
              )}
            </div>
          </Main>
          <FaqSection />
        </Content>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default CartoesRecarga;
